import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-skeleton {
    overflow: hidden;
    background: ${dt('skeleton.background')};
    border-radius: ${dt('skeleton.border.radius')};
}

.p-skeleton::after {
    content: "";
    animation: p-skeleton-animation 1.2s infinite;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), ${dt('skeleton.animation.background')}, rgba(255, 255, 255, 0));
}

[dir='rtl'] .p-skeleton::after {
    animation-name: p-skeleton-animation-rtl;
}

.p-skeleton-circle {
    border-radius: 50%;
}

.p-skeleton-animation-none::after {
    animation: none;
}

@keyframes p-skeleton-animation {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes p-skeleton-animation-rtl {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}
`;
const inlineStyles = {
  root: {
    position: 'relative'
  }
};
const classes = {
  root: ({
    props
  }) => ['p-skeleton p-component', {
    'p-skeleton-circle': props.shape === 'circle',
    'p-skeleton-animation-none': props.animation === 'none'
  }]
};
class SkeletonStyle extends BaseStyle {
  name = 'skeleton';
  theme = theme;
  classes = classes;
  inlineStyles = inlineStyles;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵSkeletonStyle_BaseFactory;
    return function SkeletonStyle_Factory(__ngFactoryType__) {
      return (ɵSkeletonStyle_BaseFactory || (ɵSkeletonStyle_BaseFactory = i0.ɵɵgetInheritedFactory(SkeletonStyle)))(__ngFactoryType__ || SkeletonStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: SkeletonStyle,
    factory: SkeletonStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SkeletonStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * Skeleton is a placeholder to display instead of the actual content.
 *
 * [Live Demo](https://www.primeng.org/skeleton/)
 *
 * @module skeletonstyle
 *
 */
var SkeletonClasses;
(function (SkeletonClasses) {
  /**
   * Class name of the root element
   */
  SkeletonClasses["root"] = "p-skeleton";
})(SkeletonClasses || (SkeletonClasses = {}));

/**
 * Skeleton is a placeholder to display instead of the actual content.
 * @group Components
 */
class Skeleton extends BaseComponent {
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Shape of the element.
   * @group Props
   */
  shape = 'rectangle';
  /**
   * Type of the animation.
   * @gruop Props
   */
  animation = 'wave';
  /**
   * Border radius of the element, defaults to value from theme.
   * @group Props
   */
  borderRadius;
  /**
   * Size of the skeleton.
   * @group Props
   */
  size;
  /**
   * Width of the element.
   * @group Props
   */
  width = '100%';
  /**
   * Height of the element.
   * @group Props
   */
  height = '1rem';
  _componentStyle = inject(SkeletonStyle);
  containerClass() {
    return {
      'p-skeleton p-component': true,
      'p-skeleton-circle': this.shape === 'circle',
      'p-skeleton-animation-none': this.animation === 'none'
    };
  }
  get containerStyle() {
    const inlineStyles = this._componentStyle?.inlineStyles['root'];
    let style;
    if (this.size) style = {
      ...this.style,
      ...inlineStyles,
      width: this.size,
      height: this.size,
      borderRadius: this.borderRadius
    };else style = {
      ...inlineStyles,
      width: this.width,
      height: this.height,
      borderRadius: this.borderRadius,
      ...this.style
    };
    return style;
  }
  static ɵfac = /*@__PURE__*/(() => {
    let ɵSkeleton_BaseFactory;
    return function Skeleton_Factory(__ngFactoryType__) {
      return (ɵSkeleton_BaseFactory || (ɵSkeleton_BaseFactory = i0.ɵɵgetInheritedFactory(Skeleton)))(__ngFactoryType__ || Skeleton);
    };
  })();
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: Skeleton,
    selectors: [["p-skeleton"]],
    inputs: {
      styleClass: "styleClass",
      style: "style",
      shape: "shape",
      animation: "animation",
      borderRadius: "borderRadius",
      size: "size",
      width: "width",
      height: "height"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([SkeletonStyle]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    decls: 1,
    vars: 7,
    consts: [[3, "ngClass", "ngStyle"]],
    template: function Skeleton_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelement(0, "div", 0);
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", ctx.containerClass())("ngStyle", ctx.containerStyle);
        i0.ɵɵattribute("data-pc-name", "skeleton")("aria-hidden", true)("data-pc-section", "root");
      }
    },
    dependencies: [CommonModule, i1.NgClass, i1.NgStyle, SharedModule],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Skeleton, [{
    type: Component,
    args: [{
      selector: 'p-skeleton',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: ` <div [ngClass]="containerClass()" [class]="styleClass" [ngStyle]="containerStyle" [attr.data-pc-name]="'skeleton'" [attr.aria-hidden]="true" [attr.data-pc-section]="'root'"></div> `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [SkeletonStyle]
    }]
  }], null, {
    styleClass: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    shape: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    borderRadius: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(Skeleton, {
    className: "Skeleton"
  });
})();
class SkeletonModule {
  static ɵfac = function SkeletonModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SkeletonModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: SkeletonModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [Skeleton, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SkeletonModule, [{
    type: NgModule,
    args: [{
      imports: [Skeleton, SharedModule],
      exports: [Skeleton, SharedModule]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(SkeletonModule, {
    imports: [Skeleton, SharedModule],
    exports: [Skeleton, SharedModule]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Skeleton, SkeletonClasses, SkeletonModule, SkeletonStyle };
